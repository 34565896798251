.App{
    text-align: center;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(79deg,#7439db,#C66FBC,#F7944D);
  }
  .auth-form-container,.log-in-form,.sign-up-form{
    display: flex;
    flex-direction: column;
  }
  .auth-form-container{
    padding: 5rem;
    border: 1px solid white;
    border-radius: 20px;
  }
  label{
    text-align: left;
    padding:0.25rem 0;
  }
  input{
    margin:0.8rem 0;
  }
  button{
    border:none;
    background-color: antiquewhite;
    cursor:pointer
  }
  .link-btn{
    background: none;
    text-decoration: underline;
  }