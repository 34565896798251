@import url("https://fonts.googleapis.com/css?family=Sofia");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Quicksand:wght@300&family=Raleway:wght@100;200;300&display=swap');


.award-cert-title1 {
    font-family: "Sofia", sans-serif;
    color: #f5f5f5;
    font-size: 4.0em;
    margin-top: 0.1em;
}

.award-cert-title2 {
    color: #f5f5f5;
    margin-top: 0.3em;
    font-family: 'Montserrat', sans-serif;
}

.award-cert-title3 {
    color: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Quicksand', sans-serif;
}

.award-cert-desc {
    color: #f5f5f5;
    font-family: sans-serif;
    font-size: 1.35em;
    margin-left: auto;
    justify-content: center;
}
.sentence-container {
    white-space: nowrap; /* Prevents line breaks */
  }
  
  .sentence {
    padding:50px; /* Add padding between sentences as desired */
    color: #f5f5f5;
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
  }
  .CEO{ 
    margin: 20px 10px;
    border-bottom: 1px solid black;
    width: 200px; 
    margin-left:20%;
    margin-top:5%;
  }
  .Manager{
    margin: 20px 10px;
    border-bottom: 1px solid black;
    width: 200px; 
    margin-left:70%;
    margin-top:-10%
  }
  .sign{
    margin-right:40%;
  }
.award-cert-body {
    width: 880px;
    height: 610px;
    text-align: center;
    padding: 40px;
    margin: 0;
    background-image: url('../assets/awardsbg.jpg');
    background-size:cover;
    /* You can adjust this percentage to decrease or increase the size */
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* This makes the background image fixed */
    background-position: center;
    /* Center the background image */
}

/* .certificate {
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #333;
    background-color: #f5f5f5;
}

h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

h2 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #f5f5f5;
}

h3 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

h4 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    margin: 5px 0;
} */

/* .signature {
    width: 150px;
    height: 2px;
    background-color: #333;
    margin: 0 auto;
    margin-top: 20px;
} */